html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  position: absolute;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  width: 8px;
  background-color: transparent;
  /*background-color: #eceef1;*/
}

::-webkit-scrollbar-thumb {
  transition: 1s ease background-color;
  -webkit-transition: 1s ease background-color;
  border: 1px solid transparent;
  border-radius: 100px;
  background-color: #8c91a1;
  background-clip: content-box;
}

::-webkit-scrollbar-corner {
  /*background-color: #e9ebee;*/
}

::-webkit-scrollbar-thumb:hover {

  background-color: #9BA3B5;
}

::-webkit-scrollbar-thumb:active {

  background-color: #6B7285;
}

::-webkit-scrollbar-thumb:vertical {
  min-height: 20px;
}
::-webkit-scrollbar-thumb:horizontal {
  min-width: 20px;
}
